import * as React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs() {
  const [passed, setPassed] = React.useState(false);

  function onChange(value) {
    setPassed(true);
    submitButton(passed);
  }

  function submitButton(passed) {
    if (passed) {
      return <button type="submit" className="mt-2 text-white bg-indigo-500 border-0 py-2 px-10 focus:outline-none hover:bg-indigo-600 rounded text-lg">Send</button>
    }
    return <button type="submit" className="mt-2 text-white bg-gray-500 border-0 py-2 px-10 focus:outline-none hover:bg-gray-600 rounded text-lg" disabled>Complete Captcha</button>
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        ( <title>{`New Beginnings Suboxone Clinic - Contact our office today!`}</title> )
        <meta name="Description" content="Contact New Beginnings Suboxone Clinic." />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Mali:wght@600&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X7TPGZBWQC"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-X7TPGZBWQC');
          `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-204995244-2"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-204995244-2');
          `}
        </script>
      </Helmet>

      <Nav menuItem="contact-us" />

      <section className="text-gray-600 body-font relative mt-10 mb-36">
        <div className="container px-5 mx-auto flex lg:flex-row sm:flex-col flex-wrap">
          <div className="lg:w-1/2 md:w-4/5 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative lg:h-screen h-96">
            <iframe width="100%" height="100%" className="absolute inset-0" frameborder="0" title="map" marginHeight="0" marginWidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=New%20Beginnings%20Portage%20In%20Lancer+(My%20Business%20Name)&ie=UTF8&t=&z=14&iwloc=B&output=embed"></iframe>
            <div className="bg-white w-11/12 relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
                <p className="mt-1">3190 Lancer Street</p>
                <p className="mt-1">Portage, IN. 46368</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
                <a className="text-indigo-500 leading-relaxed">clinic@imgportage.com</a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
                <p className="leading-relaxed">(219)292-4501</p>
              </div>
            </div>
          </div>
          <div className="lg:w-5/12 md:w-full bg-white flex flex-col md:ml-auto w-full xs:px-12 sm:px-12 mt-20 mb-10 md:mt-10">
            <h2 className="mb-8 font sm:text-center-sans text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl sm:leading-none font-roboto md:text-center sm:w-full">Get in touch <span className="text-green-500 font-bold font-marker">today</span></h2>
            <h3 className="text-gray-900 text-2xl mb-8 font-medium title-font">By Telephone</h3>
            <p className="mb-12 text-5xl text-center"> <a href="tel:12197624423" className="text-indigo-500 cursor-pointer hover:text-indigo-500 font-marker">(219)292-4501</a></p>

            
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ContactUs;
